<template>
  <div class="live-container">
    <div class="live-content">
      <div class="tab-secondNav" v-if="secondNav.length>0">
        <ul class="tab-secondNav-choose-item">
          <li
              v-for="(item, i) in secondNav"
              :key="i"
              :class="{ tabActivesecondNav: cur2 == item.id }"
              @click="changeSecondNav(item,i)"
          >
            {{ item.name }}
            <span :class="{tabActivesecondNavSpan: cur2 == item.id}">
                <span :class="{tabActivesecondNavSubSpan: cur2 == item.id}"></span>
            </span>
          </li>
        </ul>

      </div>
      <div class="live-switch-type" v-if="categoryList&&categoryList.length>0">
        <div class="live-switch-type-title"></div>
        <div class="live-switch-type-content">
          <div
            class="live-switch-type-content-li"
            :class="{
              'live-switch-type-content-li-active': cur == item.id,'imgType':item.label_name.length > 4
            }"
            v-for="(item, index) in categoryList"
            :key="item.id"
            @click="handleChangeStatus1(item)"
          >
            {{ item.label_name }}
          </div>
        </div>
      </div>
      <div class="live-switch-type">
        <div class="live-switch-type-title"></div>
        <div class="live-switch-type-content">
          <div
            class="live-switch-type-content-li"
            :class="{
              'live-switch-type-content-li-active': cur3 == item.id,'imgType':item.name.length > 4
            }"
            v-for="(item, index) in typeList"
            :key="item.id"
            @click="handleChangeStatus(index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-if="count > 0">
        <div>
            <ul class="live-ul">
      <li
        class="live-ul-li"
        v-for="(item, index) in content"
        :key="'live' + index"
      >
        <div
          @click="toNewPage(item)"
        >
          <div class="live-ul-li-banner">
            <img style="width: 100%; height: 100%" :src="item.cover" alt="" />
            <div class="listState" :class="`current${item.status}`">
              <div class="listStateFont" v-if="item.status == 1">
                <img
                  class="live-ul-li-banner-icon"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2F%E7%BC%96%E7%BB%84%203.png"
                  alt=""/>
                正在直播</div>
              <div class="listStateFont" v-else-if="item.status == 2">直播预告</div>
              <div class="listStateFont" v-else-if="item.status == 3">精彩回放</div>
            </div>
            <!--<div class="numberState" v-if="item.status == 1">
              <img
                  class="live-ul-li-banner-number-icon"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2FClip%202.png"
                  alt=""/>
                  163257253
            </div>-->
            <div class="numberAfterState1" v-if="item.status == 3">
              <img
                  class="live-ul-li-banner-numberAfter-background"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2F%E7%9F%A9%E5%BD%A2%20(2).png"
                  alt=""/>
                  
            </div>
            <div class="numberAfterState" v-if="item.status == 3">
              <img
                  class="live-ul-li-banner-numberAfter-icon"
                  src="https://image.bookgo.com.cn/%20webculture%2Fjm%2FClip%202%20(1).png"
                  alt=""/>
                  {{item.playback_view_count}}
            </div>
          </div>
          <div class="live-ul-li-bottom">
            <div class="live-ul-li-bottom-title">{{ item.title }}</div>
            
            <div class="live-ul-li-bottom-des">
              <div class="live-ul-li-bottom-des-left">
                
                <img
                  class="activity-li-bottom-icon"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_live_time.png"
                  alt=""
                />
                <div class="activity-li-bottom-icon-title">
                  {{ item.start_time*1000 | dateFormat1 }}
                </div>
              </div>
              <div class="live-ul-li-bottom-des-right" v-if="item.status == 2">
                <div class="live-ul-li-bottom-des-right-title">
                  {{item.reserveCount}}人已预约
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </li>
    </ul>
        </div>

        <!-- 分页组件 -->
        <pagination
          :total="count"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="getList"
        />
      </div>
      <!-- 没有内容显示的页面 -->
      <Nocontent
        v-if="content.length == 0"
        title="暂无文化直播，请持续关注哦～"
      ></Nocontent>
    </div>
  </div>
</template>

<script>
import { queryLiveList } from "@/api/common";
import Pagination from "@/components/Pagination";
import Nocontent from "@/views/components/Nocontent";
import {navChildList} from "@/api/header/header";
export default {
  data() {
    return {
      count: 0,
      page: 1,
      limit: 6,
      listLoading: true,
      aid: process.env.VUE_APP_AID,
      current: 0,
      cur: 0,
      typeList: [
        { id: 0, name: "全部" },
        { id: 1, name: "正在直播" },
        { id: 2, name: "直播预告" },
        { id: 3, name: "精彩回顾" },
      ],
      content: [],
      secondNav:[],
      cur2:0,
      cur3:0,
      categoryList:[],
      column_id:"",
    };
  },
  components: {
    Pagination,
    Nocontent,
  },
  mounted() {
      window.scroll(0,0);
      this.column_id = this.$route.query.column_id;
      this.cid = this.$route.query.cid;
      if(this.cid){
          this.cur2 = this.cid;
      }
      this.getChildNav();
    this.getList();
  },
  watch:{
    $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.cur3 = 0;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.categoryList = [];
      this.getList();
      this.getChildNav();
    },
  },
  methods: {
      changeSecondNav(item) {
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
          this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}`,
          //   query: {
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}/detail`,
          //   query: {
          //     tid: item.content_id,
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        }
      }
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };  
      navChildList(params).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.secondNav = res.data
        }
      });
    },
    getList() {
      let params = {
        aid: this.aid,
        type: this.cur3,
        page: this.page,
        page_size: this.limit,
        column_id:this.column_id,
      };
      if(parseInt(this.cur)>0){
          params.label_id = parseInt(this.cur);
      }
      let self = this;
      this.listLoading = true;
      queryLiveList(params).then((res) => {
        self.listLoading = false;
        this.content = res.data.datalist;
        self.count = res.data.total;
        if(res.data.label_list.length>0){
            this.categoryList = res.data.label_list;
            this.categoryList.unshift({
                id:0,
                label_name:"全部",
            })
        }
      });
    },
    handleChangeStatus(i) {
      this.page = 1;
      this.cur3 = i;
      this.getList();
    },
    handleChangeStatus1(item) {
      this.page = 1;
      this.cur = item.id;
      this.getList();
    },
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");

      } else {
        let routeUrl = this.$router.resolve({
          path: `/live-default/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/live_default_index.scss";
.tab-secondNav-choose-item{
  font-size: 0.17rem;
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item{
  display: flex;
}
.tab-secondNav-choose-item li{
  margin-left: 10px;
}
.tab-secondNav-choose-item li{
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child){
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav{
  color: #761F1E;
  position: relative;
}

.tabActivesecondNavSpan{
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan{
  width: 80%;
  background: #991C10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch{
  margin-top: 0.33rem;
}
.news-container {
  padding-top: 0.38rem;
}
.resources-ul-li-bottom-title {
    width: 100%;
    height: 0.76rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: 0.36rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0.11rem;

}
li{
  overflow: visible;
}
</style>
